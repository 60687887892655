.message {
    flex: 1;
    overflow-wrap: anywhere;
}

.user {
    font-weight: 600;
    width: 270px;
}

.time {
    width: 150px;
}