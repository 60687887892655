div .modal {
    position: static;
    opacity: 1;
    display: block;
    z-index: 100;
    transform: translate(0, 0);
    visibility: visible;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
}

div .overlay {
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
    z-index: 11;
}

div .overlay::before {
    transition: opacity 0.3s 0s;
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
}
