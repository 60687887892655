.featurePanel {
    overflow: hidden;
    cursor: pointer;
}

.disabledFeaturePanel{
    overflow: hidden;
}

.noDataAvail {
    pointer-events: none;
}
