input.inl {
    margin: 0 4px;
    display: inline-block;
    padding: .333rem .532rem;
}

.inputPrefix {
    margin-left: 6px;
}

.footer {
    margin-top: 2em;
}

.left {
    float: left;
    padding: 0.813rem 0;
}